var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.screen
        ? _c(
            "v-sheet",
            {
              staticClass: "d-flex flex-column align-center py-5 mb-2",
              attrs: { elevation: "10", color: "primary" }
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-md.png"
                }
              })
            ]
          )
        : _vm._e(),
      _vm.screen
        ? _c("invoice-toolbar", {
            attrs: { edit: _vm.edit },
            on: { action: _vm.toolbarAction }
          })
        : _vm._e(),
      _c(
        "v-slide-y-reverse-transition",
        [
          _vm.edit
            ? _c("invoice-edit", {
                attrs: { invoice: _vm.invoice, edit: _vm.edit },
                on: {
                  "update:edit": function($event) {
                    _vm.edit = $event
                  }
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-slide-y-transition", [
        !_vm.edit
          ? _c(
              "div",
              [
                _c("invoice-summary", {
                  attrs: {
                    screen: _vm.screen,
                    print: _vm.print,
                    invoice: _vm.invoice,
                    mode: _vm.mode
                  }
                }),
                _c(
                  "v-alert",
                  {
                    attrs: {
                      prominent: "",
                      icon: "mdi-information",
                      border: "top",
                      dark: "",
                      color: "primary"
                    }
                  },
                  [_vm._v("Note Goes Here")]
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        staticClass: "mb-0 pb-0",
                        attrs: {
                          cols: _vm.print ? 12 : 9,
                          offset: _vm.print ? 0 : 3
                        }
                      },
                      [
                        _c("invoice-items", {
                          attrs: { items: _vm.invoice.items }
                        }),
                        _c("v-divider")
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-row",
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: _vm.print ? 6 : 4,
                          offset: _vm.print ? 6 : 8
                        }
                      },
                      [
                        _c("psi-simple-table", {
                          attrs: {
                            headers: _vm.subtotalHeaders,
                            items: _vm.subTotals,
                            dense: ""
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-sheet",
                  { staticClass: "d-flex flex-column align-center py-3" },
                  [
                    _c("img", {
                      attrs: {
                        src:
                          "https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-sm.png"
                      }
                    }),
                    _c("h4", { staticClass: "my-2 font-weight-regular" }, [
                      _vm._v(
                        " PO Box 25582 | Portland, OR 97298 | (503) 297-1941 "
                      )
                    ])
                  ]
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }