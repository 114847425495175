<template>
    <div>
        <v-sheet
            v-if="screen"
            elevation="10"
            color="primary"
            class="d-flex flex-column align-center py-5 mb-2"
        >
            <img
                src="https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-md.png"
            />
        </v-sheet>
        <invoice-toolbar
            v-if="screen"
            :edit="edit"
            @action="toolbarAction"
        ></invoice-toolbar>
        <v-slide-y-reverse-transition>
            <invoice-edit
                v-if="edit"
                :invoice="invoice"
                :edit.sync="edit"
            ></invoice-edit>
        </v-slide-y-reverse-transition>
        <v-slide-y-transition>
            <div v-if="!edit">
                <invoice-summary
                    :screen="screen"
                    :print="print"
                    :invoice="invoice"
                    :mode="mode"
                ></invoice-summary>
                <v-alert
                    prominent
                    icon="mdi-information"
                    border="top"
                    dark
                    color="primary"
                    >Note Goes Here</v-alert
                >
                <v-row>
                    <v-col
                        :cols="print ? 12 : 9"
                        :offset="print ? 0 : 3"
                        class="mb-0 pb-0"
                    >
                        <invoice-items :items="invoice.items"></invoice-items>
                        <v-divider></v-divider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col :cols="print ? 6 : 4" :offset="print ? 6 : 8">
                        <psi-simple-table
                            :headers="subtotalHeaders"
                            :items="subTotals"
                            dense
                        ></psi-simple-table>
                    </v-col>
                </v-row>
                <v-sheet class="d-flex flex-column align-center py-3">
                    <img
                        src="https://pacific-screening-static.s3.amazonaws.com/images/logos/psi-logo-alt-sm.png"
                    />
                    <h4 class="my-2 font-weight-regular">
                        PO Box 25582 | Portland, OR 97298 | (503) 297-1941
                    </h4>
                </v-sheet>
            </div>
        </v-slide-y-transition>
    </div>
</template>
<script>
export default {
    name: "invoice-detail",
    components: {
        InvoiceItems: () => import("./InvoiceItems"),
        InvoiceToolbar: () => import("./InvoiceToolbar"),
        InvoiceSummary: () => import("./InvoiceSummary"),
        InvoiceEdit: () => import("./InvoiceEdit"),
    },
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {
            edit: false,
            paid: this.invoice.status === "Paid",
            subtotalHeaders: [
                {
                    title: "Subtotal",
                    class: "text-left",
                    key: "label",
                    width: 140,
                },
                {
                    title: "Amount",
                    class: "text-right",
                    key: "amount",

                    width: 140,
                },
            ],
            subTotals: [
                {
                    label: "Invoice",
                    amount: this.$dollarFormat(this.invoice.invoice_amount),
                },
                {
                    label: "Paid (Online)",
                    amount: this.$dollarFormat(this.invoice.paid_amount),
                },
                {
                    label: "Due",
                    amount: this.$dollarFormat(this.invoice.due_amount),
                },
            ],
        };
    },
    computed: {
        print() {
            return this.mode === "print";
        },
        screen() {
            return this.mode !== "print";
        },
    },
    methods: {
        toolbarAction(action, value = null) {
            console.log(action, value);
            switch (action) {
                case "edit":
                    this.edit = true;
                    break;
            }
        },
    },
};
</script>

<style scoped>
</style>